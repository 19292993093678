.supporter-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  object-fit: contain;
}

.swiper-container {
  position: relative;
}

.swiper-prev {
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.swiper-next {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}
