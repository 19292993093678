.membership-item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.missions {
  padding-bottom: 5px !important;
}

.benefits {
  padding-top: 5px !important;
}

.benefit-title {
  color: #821626 !important;
}

.benefit-item {
  color: #222 !important;
}
