.content-last-updated {
  color: #821626 !important;
  font-size: 24px;
  padding-top: 10px;
}

.content-sub-title {
  color: #821626 !important;
  font-weight: bold !important;
}
