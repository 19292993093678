.mobile-menu-item {
  @media (max-width: 991px) {
    padding: 12px 20px;
  }
}

.has-children {
  @media (max-width: 991px) {
    flex-grow: 1;
  }
}
